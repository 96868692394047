import { Pages } from "./Pages";
import { Utils } from "../Utils";


export class ContactsEdit extends Pages {

    initPageHandlers() {
        $(document).on("click.addPhoneField", "#addPhoneField", this.addPhoneField);
        $(document).on("click", "#studentCDD", this.setDatepicker);
        $(document).on("change", "#issuedBy select", this.addOtherDocumentField);
    }

    addPhoneField(event) {
        let $clicked = $(event.currentTarget),
            $firstField = $("#studentCPh"),
            $newInput = $("<br><input type=\"text\" id='studentCPh2'/><br><br>");
        $newInput.insertAfter($firstField);
        $(document).off("click.addPhoneField", "#addPhoneField");
        $clicked.remove();
        return false;
    }

    setDatepicker(event) {
        let $clicked = $(event.target);
        $clicked
            .datepicker({
                dateFormat: "dd.mm.yy",
                defaultDate: "0",
                changeMonth: true,
                changeYear: true,
                beforeShow: Utils.correctInputPosition
            });
        $clicked.datepicker("show");
    }

    addOtherDocumentField(event) {
        let selector = event.target,
            field = document.getElementById("studentCIs");
        if (selector.value === "other") {
            if (field.nodeName === "INPUT") {
                return;
            }
            let inputField = document.createElement("input");
            inputField.classList.add("mt-2");
            inputField.type = "text";
            inputField.id = "studentCIs";
            selector.id = "";
            selector.after(inputField);
            inputField.focus();
        } else {
            if (field.nodeName === "INPUT") {
                field.remove();
                selector.id = "studentCIs";
            }
        }
    }

}
